.HobbyOrganism {
    display: flex;

    width: 200px;
    height: 100px;

    background-image: url('../../../../pictures/java_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-size: 2em;
    
    border-radius: var(--border-radius);

    opacity: 0;
    transform: scale(0.1);
    
    transition: all 1000ms ease-in-out;
}

.Hidden {
    opacity: 0;
    transform: scale(0.1);
}

.Visible {

    width: 100%;
    height: 100%;

    opacity: 1;
    transform: scale(1);
}