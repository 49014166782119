.DarkModeButton {
    width: 50px;
    height: 50px;
    background-color: red;

    &:hover {
        background-color: rgb(240, 70, 70);
    }
}

.LightModeButton {
    width: 50px;
    height: 50px;
    background-color: lightblue;


    &:hover {
        background-color: blue;
    }
}

button {
    border-radius: .6rem;
    border: 0px;
}