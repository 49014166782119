@import url('../../../css_variables/variables.scss');

.Navbar {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;

    justify-content: center;
    align-items: center;
    
    top: 0px;
    left: 0px;
    --width: 300px;
    width: var(--width);
    max-width: 500px;

    height: 100dvh;
    transition: all 500ms ease-in-out;

    background: var(--navbar-glass-background-color);
    box-shadow: var(--navbar-glass-boxshadow);
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );

    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    margin-bottom: 25px;
    z-index: 100000;

    &* {
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
        user-select: none;
    } 
}

.Navbar:hover > div > div:nth-child(1) {
    opacity: 1;
}


.Navbar::before {
    content: "";
    position: absolute;
    top: 0px;
    right: -40px;

    width: 40px;
    height: 100dvh;
}


.Hidden {
    transform: translateX(-95%);
    border-top-right-radius: 0rem;
    
    background: var(--navbar-background-color);
    box-shadow: var(--navbar-boxshadow);

    > div:first-child {
        transform: translateX(calc(var(--width) - 215px));

        background: var(--navbar-background-color);
        box-shadow: var(--navbar-boxshadow);
    
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }
}
