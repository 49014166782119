.HamburgerMenu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    justify-content: center;
    align-items: end;

    background: transparent;

    gap: 5px;
    width: 45px;
    height: 60px;

    .line {
        background-color: var(--light-color);

        border-radius: var(--border-radius);
        

        width: 100%;
        height: 5px;
    }    
}

.MenuOverlap {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 61px;

    transform: translate(0px 0px 0px);
    transition: all 500ms ease-in-out;
}