.Navlink {
    position: relative;
    height: 100px;
    width: calc(100% - 30px);
    max-width: 250px;

    border-radius: var(--border-radius);
    background: rgba(255, 255, 255, 0.25);
    cursor: pointer;
}

.Navlink:hover > .Overlay,
.Navlink:hover > .Overlay::before  {
    opacity: 0;
}

.Navlink:hover::before {
    opacity: 1;
}

.NavlinkBorder {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    
    background: radial-gradient(
        circle 90px at var(--Xpos) var(--Ypos),
        rgb(255, 255, 255), transparent);
    border-radius: inherit;
    
    opacity: 0;
    transition: opacity ease-in-out 350ms;
}

.NavlinkContainer .Overlay {
    background: rgba(0, 0, 0, 0.3);
}

.Overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: inherit;
    transition: all ease 500ms;

    z-index: 100;
}

.OverlayText {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 2.3em;
    font-weight: bold;
    transition: opacity 500ms ease;

    z-index: 100;
}

.NavlinkContent {
    transform: translate(2px, 2px);
}

.NavlinkContent,
.OverlayText {
    user-select: none;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: inherit;
    transition: transform 100ms ease-in-out;


    text-shadow: white 1px 1px 5px;
}

.NavlinkContainer {
    transition: all 200ms ease-in-out;
    
    .InContainer {
        transition: all 200ms ease-in-out;
    }

    :not(:last-child) {
        position: absolute;
        background-color: transparent;
        
        height: 45px;
        width: 75px;

        > div:nth-child(2) {
            background-size: contain;
        }
        > div:nth-child(3){
            opacity: 0;
        }
    }
    
    :first-child {
        width: 100% !important;
        height: 100% !important;
    }

    > .InContainer:nth-child(2){
        transform: translateY(35px) translateX(85px);
        z-index: 5;
    }
    > .InContainer:nth-child(3) {
        transform: translateY(50px) translateX(55px) rotate(-5deg);
        z-index: 4;
    }
    > .InContainer:nth-child(4) {
        transform: translateY(10px) translateX(100px) rotate(-10deg);
        z-index: 2;
    }
    > .InContainer:nth-child(5) {
        transform: translateY(25px) translateX(60px) rotate(20deg);
        z-index: 1;
    }
    > .InContainer:nth-child(6) {
        transform: translateY(45px) translateX(110px) rotate(10deg);
        z-index: 3;
    }

    &:hover > .InContainer:nth-child(2) {
        transform: translateY(27px) translateX(85px) scale(1.2);
    }
    &:hover > .InContainer:nth-child(3) {
        transform: translateY(55px) translateX(20px) rotate(4deg);
    }
    &:hover > .InContainer:nth-child(4) {
        transform: translateY(5px) translateX(155px) rotate(-3deg);
    }
    &:hover > .InContainer:nth-child(5) {
        transform: translateY(2px) translateX(20px) rotate(-10deg);
    }
    &:hover > .InContainer:nth-child(6) {
        transform: translateY(50px) translateX(140px) rotate(4deg);
    }
}

.NavlinkContainer:hover .NavlinkBorder {
    opacity: 0 !important;
}

.NavlinkContainer:hover {
    background: transparent !important;
}

.Highlighted {
    z-index: -50 !important;
}