@import url('../../../css_variables/variables.scss');

.Navlink {
    position: relative;
    height: 100px;
    width: calc(100% - 30px);
    max-width: 250px;

    border-radius: var(--border-radius);
    background: rgba(255, 255, 255, 0.25);
    cursor: pointer;
}

.Navlink:hover > .Overlay,
.Navlink:hover > .Overlay::before  {
    opacity: 0;
}

.Navlink:hover::before {
    opacity: 1;
}

.Navlink::before,
.NavlinkBorder {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    
    opacity: 0;
    transition: ease-in-out 500ms;
}

.Navlink::before {
    background: radial-gradient(
        circle 90px at var(--Xpos) var(--Ypos),
        rgba(255, 255, 255, 0.2), transparent);
    border-radius: inherit;
}

.NavlinkBorder {
    background: radial-gradient(
        circle 90px at var(--Xpos) var(--Ypos),
        rgb(255, 255, 255), transparent);
    border-radius: inherit;
    z-index: -1;
}

.Overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: inherit;
    transition: all ease 500ms;
}

.OverlayText {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 1.5em;
    font-weight: bold;
    transition: opacity 500ms ease;
}

.Navlink:active > .NavlinkContent {
    transform: translate(2px, 2px);
}

.NavlinkContent,
.OverlayText {
    user-select: none;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: inherit;
    transition: transform 100ms ease-in-out;
}

