body {
    margin: 0px;
    padding: 0px;
    background: var(--base-dark-color);
}

.page {
    display: flex;

    justify-content: center;
    align-items: center;
    
    width: 100dvw;
    height: 100dvh;

    overflow: hidden;

    #Navbar {
        
        #MenuOverlap {
            position: absolute;
            
            top: 0px;
            right: 25px;

            
        }
    }
}

#NotFoundPage {
    display: flex;
    justify-content: center;

    color: var(--light-color)
}