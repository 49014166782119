:root {

    // Base color palette (Dark)
    --base-dark-color: #0d1b2a;
    --darker-blue: #1B263B;
    --hue-blue: #415A77;
    --hue-lightblue: #778DA9;
    --light-color: #E0E1DD;

    // Base color palette (Light)


    --navbar-background-color: rgb(27, 38, 59);
    --navbar-boxshadow: 5px 10px 20px rgb(27, 38, 59);
    --navbar-glass-background-color: rgb(27, 38, 59, 0.7);
    --navbar-glass-boxshadow: 5px 10px 20px rgb(27, 38, 59, 0.5);
    --border-radius: .7em;
}