.python {
    background-image: url('../../../pictures/python_bg.jpg');
}

.web-dev {
    background-image: url('../../../pictures/react_bg.jpg');
}

.java {
    background-image: url('../../../pictures/java_bg.jpg');
}

.cpp {
    background-image: url('../../../pictures/cpp_bg.jpg');
}

.database {
    background-image: url('../../../pictures/databases_bg.jpg');
}

div {
    background-size: cover;
    background-position: 0em;
    background-repeat: no-repeat;
}